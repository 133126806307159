<template>
  <div
    v-for="(id, index) in displayGroup.sale_conditions.slice(
      displayGroup.display_items,
      isOpen ? Infinity : displayGroup.display_items + 2,
    )"
    :class="isOpen ? '' : 'overflow-hidden relative max-h-28'"
  >
    <div
      v-if="!isOpen"
      class="absolute w-full h-full z-10 bg-gradient-to-b from-transparent via-90% via-white to-white pb-px"
    ></div>
    <SaleConditionRasgaPreco
      v-if="sale_conditions[id]?.selling_method?.settings?.rasga_preco"
      :saleCondition="sale_conditions[id]"
    />

    <SaleCondition v-else :saleCondition="sale_conditions[id]">
      <AddToCart v-if="isOpen" :saleConditionId="id" />
    </SaleCondition>
  </div>

  <div v-if="!isOpen" class="text-right col-span-2 relative -top-10 z-10 -mb-12">
    <MaraButton variant="highlight" size="md" class="z-20 mr-2" @click="open"
      >Mostrar mais</MaraButton
    >

    <hr class="relative -top-6 z-10 border-t-4 border-t-purple-600" />
    <hr class="relative -top-6 z-10 border-t-2 border-t-white" />
    <hr class="relative -top-6 z-10 border-t-2 border-t-purple-600" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { sale_conditions } from '../../data/default.json'
import SaleCondition from '@/components/SaleCondition.vue'
import SaleConditionRasgaPreco from '@/components/SaleConditionRasgaPreco.vue'
import AddToCart from '@/components/AddToCart.vue'
import MaraButton from '&/components/MaraButton.vue'

interface Props {
  displayGroup: any
  openCallback: any
}

const props = defineProps<Props>()
const isOpen = ref(false)

function open() {
  props.openCallback(props.displayGroup.slug, true)
  isOpen.value = true
}
</script>
